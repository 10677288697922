import { render, staticRenderFns } from "./ReportOrderCard.vue?vue&type=template&id=6b1beece&scoped=true&"
import script from "./ReportOrderCard.vue?vue&type=script&lang=js&"
export * from "./ReportOrderCard.vue?vue&type=script&lang=js&"
import style0 from "./ReportOrderCard.vue?vue&type=style&index=0&id=6b1beece&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1beece",
  null
  
)

export default component.exports