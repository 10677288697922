<template>
  <v-container class="pa-6" fluid>
    <v-row class="justify-center">
      <v-col lg="8" md="10" sm="12">
        <h2 class="sidebar-title mt-2 mb-6">Reports</h2>
        <v-row>
          <v-col col="4">
            <v-autocomplete v-model="venueId" label="Venue" :disabled="isOperatorOrManager"
              class="standart-input-filled standart-input-no-message mb-6" color="dark_grey" :items="getVenues"
              item-text="name" item-value="id" :loading="isVenueLoading" loader-height="5" filled rounded dense>
            </v-autocomplete>
          </v-col>
          <v-col col="4">
            <v-menu v-model="datePickerMenu" :close-on-content-click="false" :nudge-right="30"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date" label="Choose a date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date" show-adjacent-months @input="onDatePickerClick"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <Button text="Previous day" type="button" @handleClick="prevDayOrders" />
          </v-col>
          <v-col cols="2">
            <Button text="Next day" type="button" @handleClick="nextDayOrders" :isDisabled="isToday" />
          </v-col>
        </v-row>

        <div class="standart-card">
          <StandartLoader v-if="isLoading" />
          <template v-else>
            <v-row v-if="venueId" class="action-buttons d-flex justify-space-around align-center" no-gutters>
              <v-col cols="6">
                <p>Wolt Sales: {{ formatPrice(getWoltSale) }} EUR</p>
                <p>Wolt Rejected: {{ formatPrice(getWoltRejected) }} EUR</p>
              </v-col>
              <v-col cols="6">
                <p>Bolt Sales: {{ formatPrice(getBoltSale) }} EUR</p>
                <p>Bolt Rejected: {{ formatPrice(getBoltRejected) }} EUR</p>
              </v-col>
            </v-row>
            <div v-if="!getOrders.length" class="text-center">
              <h2>{{ infoMsg }}</h2>
            </div>
            <div v-else v-for="order in getOrders" :key="order.id">
              <ReportOrderCard :id="order.id"
                :status="order.statusId ? order.statusId : order.status && order.status.id ? order.status.id : null"
                :order="order" :state="order.stateId" :isLoading="order.isLoading"
                :orderErrorMsg="order.error ? (order.error.message ? order.error.message : '') : ''"
                :isCreatedInRKeeper="order.isCreatedInRKeeper" />
            </div>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import ReportOrderCard from '@/components/ReportOrderCard.vue';
import StandartLoader from '@/components/StandartLoader.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Button from '@/components/commonComponents/Button.vue';
import { TOAST_TYPES } from '@/data';

export default {
  name: 'Reports',
  data() {
    return {
      date: '',
      venueId: null,
      isLoading: false,
      datePickerMenu: false,
      infoMsg: 'Select a venue',
    };
  },
  components: { ReportOrderCard, StandartLoader, Button },
  created() {
    this.date = dayjs().utc().format('YYYY-MM-DD');
  },
  mounted() {
    this.resetOrders();
    if (this.isOperatorOrManager) {
      this.fetchVenues();
      this.venueId = this.getUserData.venueId;
    }
    if (this.getUserData.roleNames[0].toLowerCase() === 'dealer') {
      this.fetchVenues(`/venues/id-name?areaId=${this.getUserData.areaId}`);
    }
    if (this.getUserData.roleNames[0].toLowerCase() === 'admin') {
      this.fetchVenues();
    }
  },
  computed: {
    ...mapGetters({
      getWoltSale: 'orders/getWoltSale',
      getBoltSale: 'orders/getBoltSale',
      getWoltRejected: 'orders/getWoltRejected',
      getBoltRejected: 'orders/getBoltRejected',
      getOrders: 'orders/getAllOrders',
      getVenues: 'venues/getIdNames',
      getUserData: 'app/getUserData',
      isVenueLoading: 'venues/isLoading',
      getGeneralSettings: 'app/getGeneralSettings',
    }),
    isToday() {
      return dayjs(this.date).isSame(dayjs().utc(), 'd');
    },
    isOperatorOrManager() {
      return ['operator', 'manager'].includes(this.getUserData.roleNames[0].toLowerCase());
    },
  },
  watch: {
    venueId(val) {
      if (val && this.date) {
        this.isLoading = true;
        this.fetchAllOrders(`orders?venueId=${val}&isAsc=true&date=${this.date}`)
          .then(() => this.fetchVenue(val))
          .then(venue => this.setVenue(venue))
          .then(() => (this.isLoading = false));
      }
    },
    date(val) {
      if (!this.venueId) {
        this.infoMsg = 'Select a venue';
      }
      if (val && this.venueId) {
        this.isLoading = true;
        this.fetchAllOrders(`orders?venueId=${this.venueId}&isAsc=true&date=${val}`).then(data => {
          if (data) this.infoMsg = 'No orders';
          this.isLoading = false;
        });
      }
    },
  },
  methods: {
    ...mapActions({
      fetchAllOrders: 'orders/fetchAllOrders',
      fetchVenues: 'venues/fetchIdNames',
      fetchVenue: 'venues/fetchItem',
    }),
    ...mapMutations({
      setVenue: 'app/setCurrentVenue',
      resetOrders: 'orders/resetOrders',
    }),
    prevDayOrders() {
      this.date = dayjs(this.date).subtract(1, 'd').format("YYYY-MM-DD");
    },
    nextDayOrders() {
      this.date = dayjs(this.date).add(1, 'd').format("YYYY-MM-DD");
    },
    onDatePickerClick(e) {
      this.datePickerMenu = false;
      this.date = e;
    },
    formatPrice(price) {
      return parseFloat(price).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-buttons>* {
  flex: 1;
}
</style>
